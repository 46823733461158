
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import SpeedDial from 'primevue/speeddial';
import Tooltip from "primevue/tooltip";
import ProgressSpinner from 'primevue/progressspinner';
import Utils from '@/utility/utils';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  components: {
    DataTable,
    Column,
    InputText,
    Calendar,
    Row,
    ColumnGroup,
    ProgressSpinner,
    Dropdown,
    Button
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    tableName: {
      type: String,
      default: 'PayablesTable',
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
  },
  emits: ['pay-now'],

  data() {
    return {
      isLoading: false,
      rowsPerPage: 5,
      dateRange: null as any,
      filters: {
        invoice: {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        vendor_name: {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        status: { value: [], matchMode: FilterMatchMode.IN },
        type: { value: [], matchMode: FilterMatchMode.IN },
      },
      invoices: [],
      selectedStatus: null,
      selectedType: null,
      stati: [
        { name: "No Invoice", code: "A" },
        { name: "Open Invoice", code: "O" },
        { name: "Precheck Processing", code: "P" },
        { name: "Paid", code: "C" },
      ],
      types: [
        { name: "Prepayment type", code: "P" },
        { name: "Invoice", code: "I" },
        { name: "Debit", code: "D" },
      ],
    };
  },
  created() {
    this.dateRange = this.range ? this.range.range : null;
  },
  computed: {
    ...mapGetters({
      getDefaultPayablesByTabLabel: 'accountingPayables/getDefaultPayablesByTabLabel',
    }),
    getTotalBalance() {
      let total = 0.00;
      this.invoices.forEach((invoice: any) => {
        total += parseFloat(invoice.balance);
      });
      return this.formatCurrency(total);
    },
  },
  methods: {
    ...mapActions({
      fetchAccountsPayable: 'accountingPayables/fetchAccountsPayable',
      addOpenedAccountingTab: 'accountingInquiry/addOpenedAccountingTab',
    }),
    async handleClearFilterByStatus(): Promise<void> {
      this.selectedStatus = null;
      this.getPayables();
    },
    async handleClearFilterByType(): Promise<void> {
      this.selectedType = null;
      this.getPayables();
    },
    async handleFilter(): Promise<void> {
      this.getPayables();
    },
    async getPayables(): Promise<void> {
      this.isLoading = true;
      await this.fetchAccountsPayable({
        custId: '',
        status: this.selectedStatus,
        sortBy: null,
        id: null,
        type: this.selectedType,
        dueDateStart: Utils.roverDateString(new Date(this.dateRange[0])), 
        dueDateEnd: this.dateRange[1] ? (Utils.roverDateString(new Date(this.dateRange[1]))) : undefined, 
        rangeLabel: this.range?.label
      }).then(() => {
        this.invoices = this.getDefaultPayablesByTabLabel(this.range?.label)[0].items;
      }).finally(() => {
        this.isLoading = false;
      });
    },  
    getTypeTitle(type: string): string {
      return this.types.find((typ: any) => typ.code === type)?.name ?? "";
    },
    getStatusTitle(status: string): string {
      return this.stati.find((stat: any) => stat.code === status)?.name ?? "";
    },
    formatCurrency(value: number) {
      value = value ? value : 0;
      let number = typeof value === 'string' ? parseFloat(value) : value;
      return `$ ${number.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
    },
    onRowSelect(event: any) {
      const payableData = event.data;
      const data = {
        record: payableData,
        old_record: JSON.parse(JSON.stringify(payableData)),
        accountType: 'accounts-payable',
        lineItemsMap: [],
        lineItemsActiveTab: 0,
        activeTab: 0,
      }
      this.addOpenedAccountingTab(data)
      this.$router.push(`/accounting/accounts-payable/${payableData.ap_id}`)
    },
    payNow(event: any) {
      console.log('pay now', event);
      this.$emit('pay-now', event);
    },
    getSpeedDialActions (data: any) {
      return [
        {
          label: 'Config',
          icon: 'pi pi-cog',
          command: () => {
            console.log('config', data);
          }
        },
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
           console.log('edit', data);
          }
        },
        {
          label: 'Send',
          icon: 'pi pi-envelope',
          command: () => {
            console.log('send', data);
          }
        },
        {
          label: 'Print',
          icon: 'pi pi-print',
          command: () => {
            console.log('print', data);
          }
        },
      ]
    }
  },
  watch: {
    range(val: any) {
      this.dateRange = val.range;
    },
    dateRange(val: any) {
      this.dateRange = val;
      if (!this.dateRange || !this.dateRange[0] || this.dateRange[1] === null) {
        return;
      }
      
      this.isLoading = true;
      this.fetchAccountsPayable({
        dueDateStart: Utils.roverDateString(new Date(this.dateRange[0])), 
        dueDateEnd: this.dateRange[1] ? (Utils.roverDateString(new Date(this.dateRange[1]))) : undefined, 
        rangeLabel: this.range?.label
       }).then(() => {
        this.invoices = this.getDefaultPayablesByTabLabel(this.range?.label)[0].items;
      }).finally(() => {
        this.isLoading = false;
      })
    },
  }
});
